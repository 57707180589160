import React from 'react'
import { Modal } from 'react-bootstrap'
import styles from "./SatyugMeaning.module.scss"
import { CrossIcon } from '../../../../assets/svg/svgicons'

const SatyugMeaning = ({ handleClose, show }) => {
    return (
        <Modal
            centered
            show={show}
            onHide={handleClose}
            className={styles.meaning}
        >
            <Modal.Header>
                <h2>Satyug</h2>
                <button onClick={handleClose} className={styles.close_btn}>
                    <CrossIcon />
                </button>
            </Modal.Header>
            <Modal.Body>
                <p>Sat in Sanskrit stands for "Right and Truthful" <br /> and <br /> Yug stands for "Period of Time"</p>
            </Modal.Body>
        </Modal>
    )
}

export default SatyugMeaning
