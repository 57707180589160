import React from 'react'
import styles from "./PageHeading.module.scss";

const PageHeading = ({ aos, className, heading }) => {
    const animation = aos ? {
        "data-aos": "fade-up",
        "data-aos-duration": "500",
    } : {}
    return (
        <div className={`${styles.page_heading} ${className || ""}`}>
            <h2 {...animation}>{heading}</h2>
        </div>
    )
}

export default PageHeading

PageHeading.defaultProps = {
    aos: true,
}