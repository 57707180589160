import React from 'react';
import { Container } from 'react-bootstrap';
import HeadingText from '../../common/HeadingText/HeadingText';
import PageHeading from '../../common/PageHeading/PageHeading';
import styles from "./TermServices.module.scss";
import Backbutton from '../../common/Backbutton/Backbutton';

const TermServices = () => {
    return (
        <section className={styles.terms_services}>
            <Container>
                <div className={styles.heading}>
                    <Backbutton className={styles.bckbtn} />
                    <HeadingText
                        heading="Terms of Service"
                    />
                </div>
                <ul>
                    <li>
                        <small>Grant of Licence to use the services</small>
                    </li>
                    <li>
                        <p>Subject to your acceptance of and full compliance with these Terms, Satyug Labs PVT. LTD grants to you a limited, non-exclusive, non-transferable, revocable license to access its services for your non-commercial personal entertainment use only, by accessing the services through your web browser or applications provided by satyug, authorized social networking websites, service providers or partners. You agree that you may not use the services for any other purpose, or copy or distribute the services.
                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <PageHeading
                            aos={false}
                            heading="Right to Terminate or Change services"
                        />
                    </li>
                    <li>
                        <p>Your usage using complete and continuing compliance. Any usage of these services in breach of those conditions will be handled as a breach of Satyug Labs PVT. LTD. services.
                        </p>
                    </li>
                    <li>
                        <p>
                            We reserve the right to terminate your access and usage of those services in the event that you violate these Conditions. In this event, we reserve the right to suspend or permanently stop the services without notice without accountability. In these cases, you may eliminate access to your Account, password, username, character, and you'll forfeit all connected entitlements, such as Virtual Money, Virtual Goods (such as virtual vehicles, gear, factors, standings, rankings, evaluations, or some other electronic objects appearing in, originating out of or connected to the service programs ). You agree that these Terms will survive your Account for any reason or the termination of these professional services.

                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <PageHeading
                            aos={false}
                            heading="Our Right to Change or Update Services
"
                        />
                    </li>
                    <li>
                        <p>satyug reserves the right to change, remove, or update parts of its services without prior intimation or consideration of any sort. As a user you agree that any game functionalities, features, or provisions in the game options in the apps may be removed, changed, or updated without your consent. satyug holds complete control of its products and may bring changes as and when required.
                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <PageHeading
                            aos={false}
                            heading="Our intellectual Property Rights
"
                        />
                    </li>
                    <li>
                        <p>
                            Any use of these materials appearing in the website or the services not authorized by these Terms is a violation of copyright, trademark, and other legislation, and might result in criminal prosecution or civil penalties.


                        </p>
                    </li>
                    <li>
                        <p>
                            Except for the limited right to access and use the services and the site, we do not grant or transfer to you any other rights of any kind, including any rights in any Virtual Currency, Virtual Goods (including virtual vehicles, equipment, points, standings, rankings, evaluations, or some other digital items appearing in, originating from or associated with this site), or some otherattributes appearing in, originating from or associated with the services or the site. No ownership rights of any kind, including any patent, trademark, copyright or other intellectual property rights, are assigned or transferred for you, by accessing or utilizing the services or this website, downloading content from or uploading material to this site, or by purchasing any Virtual Goods.
                        </p>
                    </li>
                    <li>
                        <p>
                            Except as explicitly permitted herein, without our written consent, you agree not to copy, redistribute, publish or otherwise exploit material from this website or the services. All comments, feedback, tips, ideas, and other submissions disclosed, submitted, or offered to us in relation to the usage of these services is the property of satyug. You agree that we can use, sell, exploit and disclose some of your comments in any fashion, without restriction and without compensation to you.

                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <PageHeading
                            aos={false}
                            heading="Intellectual Property Rights of Others"
                        />
                    </li>
                    <li>
                        <p>
                            All trademarks, service marks, and trade names associated with the products or services that appear on or are referenced in this site are the exclusive property of their respective owners.
                        </p>
                    </li>
                    <li>
                        <p>
                            All other product names and logos are the trademarks of their respective owners. In addition, other images, logos, pictures or other material may be trade names or registered trademarks of their respective owners, and may be protected by international copyright laws. We grant no right to copy or use any of these materials.

                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <PageHeading
                            aos={false}
                            heading="Virtual Currencies and Goods"
                        />
                    </li>
                    <li>
                        <p>
                            The services might consist of virtual currency ("Virtual Money"), such as, but not restricted to money, points, standings, rankings, evaluations, or some other features emerging in, originating from or connected with this website, which might be obtained for real cash if you're a legal adult in your state of residence. The services may also have virtual coins or gems, rankings, evaluations, chat time, or some other electronic things appearing in, originating out of or connected with this website ("Virtual Goods") that might be bought from Satyug Labs' service programs for real world cash or traded for Virtual Money. Note that the virtual currency is only for the purpose of playing the game and cannot be redeemed into real currency.

                        </p>
                    </li>
                    <li>
                        <p>
                            You've got a limited revocable, non-transferable permit to utilize Virtual Currency and the Virtual Goods in this website and our solutions. But you don't have any intellectual property right or permit title or right in or to some digital Money, Virtual Goods or any other characteristics associated with utilization of their services or this website; your best to purchase a particular quantity of solutions or Virtual Goods by paying with Virtual Money shall stay unaffected.

                        </p>
                    </li>
                    <li>
                        <p>
                            Irrespective of the way of transport, the indirect or direct redemption or exchange of Virtual Goods and Virtual Currency for real world cash, products or other things of worth from some other party is prohibited except where authorized within the solutions. Any effort to do this might lead to an immediate termination of your consumer Account and is in breach of the Terms and accessibility to potential legal actions, in addition to these services.

                        </p>
                    </li>
                    <li>
                        <p>
                            Any purchases of Virtual Currency from satyug by you or Virtual Goods are closing. No refunds will be granted, except at satyug's discretion.

                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <PageHeading
                            aos={false}
                            heading="You own your content"
                        />
                    </li>
                    <li>
                        <p>The services can allow or entice you to talk or take part in blogs, message boards, online forums and other performance and might supply you with the chance to make, publish, post, display, transmit, perform, publish, and broadcast or broadcast content and stuff into satyug through this website, including, without limitation, writings, text, images, comments, forum articles, chat articles, messages, communications, profiles, and personally identifiable data, tips, links, e-mails, songs, audio, images, pictures, images, movie, applications, tech, code and other content and data perceived, made accessible, or relegated to or in your services. Contains any comments, or suggestions, and may be offered by you or third parties that you may provide concerning additional the solutions, or this website, including any replies.

                        </p>
                    </li>
                    <li>
                        <p>
                            You've got sole responsibility on your own. We don't pre-screen any submitted by anybody and we aren't accountable for your own. We've got no duty to monitor or use. The terms apply to voice chat as well as text chat.


                        </p>
                    </li>
                    <li>
                        <p>
                            satyug does not have any obligation screen to take, inspect, track, distribute, store, keep or delete any material that you post or submit in the apps or anywhere. We reserve the right, at our sole discretion, to refuse to post, remove or delete any that you submitted without notice for any reason at any moment to the website. We re-format, might proceed, distort, vague, edit, change or change notice to you and without accountability.
                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <PageHeading
                            aos={false}
                            heading="Our Rights
"
                        />
                    </li>
                    <li>
                        <p>
                            You waive any moral rights or rights of privacy or publicity you might have. If, for any reason, you're deemed to have kept, under applicable law, any right, interest or title in or to any part of your own, you consent to and hereby do assign to satyug your entire right, title and interest in as well as your own, without further consideration, below applicable patent, copyright, trade secret, trademark and other related laws or rights, in perpetuity.

                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <PageHeading
                            aos={false}
                            heading="User account and password
"
                        />
                    </li>
                    <li>
                        <p>The services might be provided via a social networking site by which you might access this website to you. You might have the ability to use your user accounts or user ID of the site through. As an alternative, you might be asked to start an account ("Account") right with satyug by completing the registration procedure and providing accurate, accurate, current and complete information regarding yourself ("Registration Data"). You agree to promptly update. You may not get the services In case you have more than one Account to a social networking site. Some providers may ask that you make a username or character to prepare your Account. You may not impersonate a user or another person, try to find a password, other account information or other information from an individual, or collect or harvest email addresses or other info.
                        </p>
                    </li>
                    <li>
                        <p>
                            You're responsible for maintaining the confidentiality of your Account data (like username and password ) and are accountable for all activities which occur under your Account. You're also accountable for any losses or damages to any customers of their services, or some other parties caused by somebody else using your Account, with or without. You have to notify us immediately of any unauthorized use or theft from your Account or breach of security. Your Account may be terminated if somebody uses it to take part in action that violates these Terms or is prohibited or wrong.

                        </p>
                    </li>
                    <li>
                        <p>
                            You may not use anyone else's account password, username, or character, and you may not buy, sell, trade, rent, rent, permit, grant a security interest , move to or allow another individual to use your account password, username, character, Virtual Money, Virtual Goods (such as virtual vehicles, gear, factors, standings, rankings, evaluations, or some other electronic objects appearing in, originating from or connected with this website ).The website won't cause any issue or conflict brought on by accounts sharing.
                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <PageHeading
                            aos={false}
                            heading="In app purchases and payments"
                        />
                    </li>
                    <li>
                        <p>You may be required to pay for certain services, including an exchange for Virtual Currency, a purchase of Virtual Goods, and upgrades. You must have a valid Account to pay for and participate in these services. You are responsible for all charges and usage on your Account, including applicable taxes, including all purchases made by you or anyone that uses your Account.

                        </p>
                    </li>
                    <li>
                        <p>
                            You may pay using the methods available for the particular services, including credit card, debit card, PayPal, UPI or other similar payment accounts, and you agree to the terms and conditions applicable to each payment method you choose. When you provide credit card or other payment information, you represent that you are the authorized user of the credit card or other payment method. You must provide current, complete, and accurate information for your billing account. You must promptly update all information to keep your billing account current, complete, and accurate (such as a change in billing address, credit card number, or credit card expiration date), and you must promptly notify us if your credit card is canceled, lost or stolen. We may use a third party credit card updating service to obtain current expiration dates on credit cards provided by you. Customers who use a payment method other than credit card may incur an additional payment processing fee.
                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <PageHeading
                            aos={false}
                            heading="No Refund policy"
                        />
                    </li>
                    <li>
                        <p>In-app purchases are not refundable. Satyug follows no-refund policy for all in-app purchases.

                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <PageHeading
                            aos={false}
                            heading="Advertising"
                        />
                    </li>
                    <li>
                        <p>We reserve the right to use and disclose the collected, non-personal data for purposes of advertising by us or our partners and contractors. We may employ third parties for ad serving technologies who may use certain methods to collect information as a result of ad serving through services.

                        </p>
                    </li>
                    <li>
                        <p>
                            We and these entities may use various identifiers to collect information about your use of the App, including but not limited to your IP address, MAC address, device identifiers, software and hardware information, time zone and usage information. This information may be used by us and others to, among other things, determine the popularity of certain content, deliver contextual advertising and content and better understand your activity on the Apps.

                        </p>
                    </li>
                    <li>
                        <p>
                            Third-party ad serving technologies are integrated into the App, so if you do not want to be subject to these technologies, please do not use or access the Apps.
                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <PageHeading
                            aos={false}
                            heading="Third Party Advertising"
                        />
                    </li>
                    <li>
                        <p>
                            The ads shown in our apps may make users buy or carry out monetary transactions for third-party products or services. We are not liable for any loss of third-party in-app purchases happening outside of our apps. This Privacy Policy does not apply to the practices of companies that we do not own or control. Any information you provide to sites, applications or services advertised through our apps will be covered by their applicable privacy policies specified therein. Please be sure to read the privacy policies of any third-party applications, services and sites that you visit or provide permission to access your information. It is those third parties’ responsibility to protect any information you give them, so we will not be held liable for their use of your personally identifying information. In case you face any problem with any product or service you will have to contact the providers of those products or services to get your issue resolved. satyug will not be responsible any for lack of authenticity of products or services or misuse of your personal information by the third party service providers.

                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <PageHeading
                            aos={false}
                            heading="Rules of Conduct: Your Responsibilities"
                        />
                    </li>
                    <li>
                        <p>
                            You are liable and responsible for all your tasks using this site and its services. You agree to follow posted forum guidelines and code of conduct on the fan page, game, and any official satyug websites.
                        </p>
                    </li>
                    <li>
                        <p>
                            Your use of this website and the services is governed. The Rules of Conduct are illustrative and are not meant to be comprehensive. We reserve the right to determine what behavior might be a violation and to take proper actions as we consider necessary, including termination of your Account and exclusion from participation in the services. We reserve the right to alter the Rules of Conduct at any time to the extent that is in perspective of any remuneration or attribute by you just as well as reasonable.By accessing and using the services, you agree that you will not engage in, attempt or encourage any of the following activities
                        </p>
                        <ol>
                            <li>
                                <p>Cheat, or use, launch, develop, or distribute any spider, “bot,” “macro,” or other “cheat utility” software program or applications which are designed to cheat, hack, crack, interfere with, disrupt, or modify the intended operation and experience of the services
                                </p>
                            </li>
                            <li>
                                <p>Upload, disseminate or transmit files that contain viruses, Trojan horses, worms, time bombs, spyware, adware, cancelbots, corrupted files or data, or any other similar invasive software or programs designed to damage, interrupt, destroy, or limit the functionality or disrupt the operation of the services, any software, hardware, telecommunications, networks, servers or other equipment, or other users’ computers, or that launches any unauthorized script or other software
                                </p>
                            </li>
                            <li>
                                <p>Disrupt, attempt to, or otherwise assist in the disruption of any computer used to support the services or any other party’s uninterrupted use and enjoyment of the services
                                </p>
                            </li>
                            <li>
                                <p>Interfere with or circumvent any security feature of the services or any feature that restricts or enforces limitations on use of or access to the services
                                </p>
                            </li>
                            <li>
                                <p>Restrict, disturb, disrupt or inhibit any other user from using or enjoying the services, for example, by repeatedly posting the same message or excessive posts within chat rooms (“flooding”), disrupting the flow in chat rooms by hitting the return key repeatedly, inputting excessively large images so the screen goes by too fast to read, using of excessive “shouting” (all caps), or “spamming.”
                                </p>
                            </li>
                        </ol>
                    </li>
                </ul>
                <ul>
                    <li>
                        <PageHeading
                            aos={false}
                            heading="No commercial use"
                        />
                    </li>
                    <li>
                        <ol>
                            <li>
                                <p>
                                    Reproduce, transmit, sell, use or exploit the services or this site for commercial purposes that are not expressly authorized in these Terms, including use as a cyber café, computer gaming center, network play over the Internet, gaming network, or connection to an unauthorized server that emulates the game experience
                                </p>
                            </li>
                            <li>
                                <p>
                                    Use the services or this site to advertise, market, or promote any goods, services, website, pyramid scheme, or other multi-tiered marketing scheme; solicit funds or goods; or solicit anyone to join or become a user of any other website or other organization
                                </p>
                            </li>
                            <li>
                                <p>
                                    Post messages for any purpose other than personal communication as an individual user, including advertising or promotional messaging, chain letters, surveys, contests, chain letters, junk email, spam, other unsolicited messages, or other commercial activities
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <small>
                            No fraudulent use
                        </small>
                    </li>
                    <li>
                        <ol>
                            <li>
                                <p>
                                    Use the services or this site for fraudulent transactions or for any purpose that violates any applicable federal, state, local, or foreign laws, regulations, ordinances or treaties
                                </p>
                            </li>
                            <li>
                                <p>
                                    Impersonate another person or user, create a false identity, trick, defraud or mislead satyug or any users, for any purpose, including misleading others to attempt to access an Account, user name, password, persona, Virtual Currency or Virtual Goods, or any personal information about a user
                                </p>
                            </li>
                            <li>
                                <p>
                                    Create an Account based on information that is untrue, inaccurate, not current or incomplete
                                </p>
                            </li>
                            <li>
                                <p>
                                    Make improper use of satyug support services or submit false reports of abuse or misconduct;
                                </p>
                            </li>
                            <li>
                                <p>
                                    Disguise or mislead any person as to the source of your or other information you submit or use tools which anonymize your internet protocol address (e.g. anonymous proxy) to access the services or this site
                                </p>
                            </li>
                            <li>
                                <p>
                                    Collect, gather, harvest (in an automated manner or otherwise), disclose, reveal or use in any manner the Account, user name, password, persona, Virtual Currency or Virtual Goods, or any personal information about another individual, including address, phone number, e-mail address, credit card number, or any information that may be used to track, contact or impersonate the individual.
                                </p>
                            </li>
                        </ol>
                    </li>
                </ul>
                <ul>
                    <li>
                        <PageHeading
                            aos={false}
                            heading="No unauthorized use"
                        />
                    </li>
                    <li>
                        <ol>
                            <li>
                                <p>
                                    Host, or intercept, emulate or redirect the proprietary communication protocols used by satyug in connection with the services and this site, regardless of the method used, including protocol emulation and reverse engineering, or create derivative works of the services or this site, in whole or in part
                                </p>
                            </li>
                            <li>
                                <p>
                                    Copy, reproduce, translate, reverse engineer, modify, disassemble, decompile, reverse assemble, or attempt to discover any software (source code or object code) related to the services and this site
                                </p>
                            </li>
                            <li>
                                <p>
                                    Modify, impair, disrupt, alter or interfere with the use, features, functions, operation or maintenance of the services and this site, including adding unauthorized components or modifying this site or the online game experience
                                </p>
                            </li>
                            <li>
                                <p>
                                    Use any third-party software or other means that intercepts, mines or otherwise collects information from or through this site
                                </p>
                            </li>
                            <li>
                                <p>
                                    Frame or otherwise impose editorial comment, commercial material or any information on this site, or cover, remove, obliterate, obscure or obstruct any notice, banner, advertisement, proprietary notices or labels, or any portion of the services or this site
                                </p>
                            </li>
                            <li>
                                <p>
                                    Remove, obscure, or alter notices of copyright, patent, trademark, or other proprietary rights affixed to any or this site
                                </p>
                            </li>
                            <li>
                                <p>
                                    Sell, license, distribute, copy, modify, publicly perform or display, transmit, publish, edit, adapt, create derivative works from, or otherwise make unauthorized use of any
                                </p>
                            </li>
                            <li>
                                <p>
                                    Attempt to use the services or this site on or through any service that is not controlled or authorized by satyug; and Violate these Terms, any other applicable agreement or terms governing the access or use of the services (including the terms of use of any social networking website through which the services may be accessed), or any federal, state or local, or international law, regulation, ordinance, or treaty.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Purchase, sell, trade, rent, lease, license, grant a security interest in, transfer to or allow any other person to use or access your Account, password, user name, persona, Virtual Currency or Virtual Goods, whether or not in exchange for real currency or items of monetary value; or offer, advertise, market, or promote any of the foregoing.
                                </p>
                            </li>
                        </ol>
                    </li>
                </ul>
                <ul>
                    <li>
                        <PageHeading
                            aos={false}
                            heading="Changes to terms of use"
                        />
                    </li>
                    <li>
                        <p>We reserve the right to alter these Conditions at any moment and whatsoever. We'll try to provide you advance notice of any change in these Terms by sending a message telling you, and by posting on this website. We reserve the right to make adjustments as depending on our sole discretion. You agree to check this website for info and conditions that govern the use of this website and their services, and also to read any adjustments. Utilizing our services become effective and constitutes your acceptance of the Terms. You don't have permission to utilize the professional services if you don't consent to these Conditions and you have to immediately discontinue any use of these services.
                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <PageHeading
                            aos={false}
                            heading="Terminating Your Account"
                        />
                    </li>
                    <li>
                        <p>
                            When we terminate your Account, you may eliminate access to your Account, password, username, character, and you'll forfeit all associated entitlements, such as those quantities of digital Money for which you haven't paid in actual cash, Virtual Goods (such as virtual vehicles, gear, factors, standings, rankings, evaluations, or some other electronic things appearing in, originating from or connected with this website ).

                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <PageHeading
                            aos={false}
                            heading="Data Collection"
                        />
                    </li>
                    <li>
                        <p>
                            You might be asked to start an Account straight by providing information regarding yourself and completing the enrollment procedure. The providers may be provided via a social networking site through to you. Information is subject to the policies of the social networking website. By utilizing or accessing a game, program, or action on this website, you could be granting the social networking site permission to talk about any other information and your email address .

                        </p>
                    </li>
                    <li>
                        <p>
                            We utilize this information for other functions, rules, game management and authentication, copy protection, account blocking, in addition to system.
                        </p>
                    </li>
                    <li>
                        <p>
                            By using and accessing this website and also the services, you agree to our collection and use of your private information, including the transport of the information by satyug and its affiliates for usage, processing and storage. Within these services, Additionally, we might supply communications, such as service announcements and administrative messages to you.
                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <PageHeading
                            aos={false}
                            heading="Third-Party Data Collection and Use of End-User Information:"
                        />
                    </li>
                    <li>
                        <p>
                            When you utilize a Satyug Labs' services through a Third Party Platform--such as Facebook--you permit us to collect information. You allow us to get information contained in cookies placed on your own device from the Third Party Platform as allowed by the conditions of privacy preferences and your agreement . We can share this info as allowed by the conditions of privacy preferences and your agreement together with the Third Party Platform. We'll share some of this information we gather with sellers and service providers working with us to encourage the operations of our cellular program from you. By way of instance, we utilize a service to also help us organize mobile and website application analytics and advertising tracking.

                        </p>
                    </li>
                    <li>
                        <p>
                            Our third party advertisement tracking and analytics firm never sends notifications mails, or other messages. If you'd like to opt from analytics and advertisement tracking, please see here. Your decision doesn't restrict our capability to collect information for sellers, subject to the conditions of the privacy policy or to email you.
                        </p>
                    </li>
                </ul>
            </Container>
        </section>
    )
}

export default TermServices
